import {useState} from 'react'
import {AiFillGithub, AiOutlineYoutube, AiOutlineLink} from 'react-icons/ai';

function Collapse(props) {
	const [show, setShow] = useState(false);

	const showDropdown = (e) => {
		setShow(!show);
	}

	const hideDropdown = (e) => {
		setShow(false);
	}

  	return (
    	<div>
			<article className='portfolio__item' key={props.id} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
				<div className='portfolio__item-image'>
					<img src={props.image} alt={props.title} />
				</div>
				<h3>{props.title}</h3>
				{show && (
					<div className='portfolio__item-collapse'>
						<p>{props.description}</p>
						<a href={props.github} target='_blank'>
							Source code 
							<AiFillGithub size='1.5rem'/>
						</a>
						{props.demo &&
							<a href={props.demo} target='_blank'>
								Demo 
								<AiOutlineYoutube size='1.5rem'/>
							</a>}
						{props.web &&
							<a href={props.web} target='_blank'>
								Web page 
								<AiOutlineLink size='1.5rem'/>
							</a>}
				</div>
				)}
			</article> 
		</div>
  	)
}

export default Collapse