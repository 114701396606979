import './Contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {FcCheckmark} from 'react-icons/fc'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {
  const [sent, setSent] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_oolni2l', 'template_n1yc5ce', form.current, 'THdAYv7dNw3HFyCAg')
      .then((result) => {
          console.log(result.text);
          setSent(true);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get in touch</h5>
      <h2>Contact me</h2>
      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>tdroppelmannp@gmail.com</h5>
            <a href='mailto::tdroppelmannp@gmail.com' target='_blank'>Send a message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Name' required/>
          <input type='email' name='email' placeholder='Your email' required/>
          <textarea name='message' placeholder='Your message' rows='7' required></textarea>
          <div className='form-send-button'>
            <button className='btn btn-primary' type='submit'>Send message</button>
            {sent && <p className='contact__sent'>Message sent! <FcCheckmark/></p>}
          </div>
        </form>
      </div>
    </section>
  )
}

export default Contact