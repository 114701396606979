import './Header.css'
import ME3 from '../../assets/me3.png'
import Typewriter from 'typewriter-effect';
import CV from '../../assets/CV2.pdf'


function Home() {
  return (
    <section id="home">
      <div className="container header__container">
        <h5>Hello! I'm</h5>
        <h1>
          <Typewriter
            style="width:20%;"
            options={{
                delay: 100,
                deleteSpeed: 70
            }}
            onInit={(typewriter) => {
                typewriter.typeString("Tomas Dro")
                .pauseFor(800)
                .deleteChars(6)
                .typeString("ás Droppelmann")
                .start();
            }}
          />
        </h1>
        <h5 className='text-light'>Software Engineer</h5>
        <div className="cta">
          {/* <a href={CV} className="btn" download>Download CV</a> */}
          <a href="#contact" className="btn">Let's talk</a>
        </div>
        <div className="me">
          <img src={ME3} alt="me"/>
        </div>
      </div>
    </section>
  )
}

export default Home