import './Portfolio.css'
import APP from '../../assets/app.jpeg'
import WFA from '../../assets/wfa.jpeg'
import BOT from '../../assets/bot.jpeg'
import GAME from '../../assets/game.jpeg'
import PARTY from '../../assets/party.jpg'
import FINANCE from '../../assets/finance.jpeg'
import C from '../../assets/C.jpeg'
import Collapse from './Collapse'

function Portfolio() {
    const data = [
        {
            id: 0,
            title: "Finance Manager",
            description: 'Python program that allows you to manage your personal finances, categorizing different expenses, generating reports and exporting the data automatically to Google Sheets.',
            image: FINANCE,
            github: 'https://github.com/tedroppelmann/finance-manager/tree/main',
        },
        {
            id: 1,
            title: "What's in my fridge? App",
            description: 'React Native app that recommends cooking recipes depending on the ingredients available at home.',
            image: APP,
            github: 'https://github.com/tedroppelmann/what-is-in-my-fridge-app',
            demo: 'https://www.youtube.com/watch?v=wRlryiSjeDg&feature=youtu.be',
        },
        {  
            id: 2,
            title: 'Work From Anywhere platform',
            description: 'Full stack developer of the Work From Anywhere mock-up platform, a project that seeks to group different towns in Italy on a single platform with the possibility of going to work there by booking houses or workspaces.',
            image: WFA,
            github: 'https://github.com/tedroppelmann/work-from-anywhere-app',
            web: 'https://work-from-anywhere2022.firebaseapp.com',
        },
        {
            id: 3,
            title: "Max Tree Filtering",
            description: 'Program written in C that uses the Max Tree data structure to segment a greyscale image, and then uses this tree to generate a new image, to which a particular filter is applied.',
            image: C,
            github: 'https://github.com/tedroppelmann/max-tree',
        },
        {
            id: 4,
            title: "Scalper Bot",
            description: 'Python scalper bot that uses the CAPM theory to calculate the optimal portfolio in the cryptocurrency market.',
            image: BOT,
            github: 'https://github.com/tedroppelmann/scalper-bot',
        },
        {
            id: 5,
            title: "Coffe Bar Game",
            description: 'PC game program developed using Python and PyQt5 library for the interactive GUI.',
            image: GAME,
            github: 'https://github.com/tedroppelmann/coffee-bar-game',
        },
        {
            id: 6,
            title: "Party platform",
            description: 'Auction "parties marketplace" platform developed using Ruby on Rails and PostgreSQL, following the MVC pattern architecture.',
            image: PARTY,
            github: 'https://github.com/tedroppelmann/proyecto-software',
        },
    ]

    return (
        <section id='portfolio'>
            <h5>What have I done</h5>
            <h2>My projects</h2>
            <div className='container portfolio__container'>
                {data.map((props) => {
                    return (
                        <Collapse {...props}/>
                    )
                })}
            </div>
        </section>
    )
}

export default Portfolio