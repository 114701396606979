import {useState}  from 'react'

function WorkItem(props) {
	const [show, setShow] = useState(false);

	const showDropdown = (e) => {
		setShow(!show);
	}

	const hideDropdown = (e) => {
		setShow(false);
	}
	
  	return (
    	<div className='experience__work-item' key={props.id} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
			<article>
				<small>{props.location}</small>
				<h2>{props.title}</h2>
				<h4>{props.place}</h4>
				<small>{props.years}</small>
			</article>
			{show &&
			<ul className='experience__content-bullet'>
				<div className='divider'></div>
				{props.description.map((item) => {
					return (
						<li className='experience__content-bullet-item'><small>{item}</small></li>
					)
				})}
			</ul>}
		</div>
  	)	
}

export default WorkItem