import './Experience.css'
import PUC from '../../assets/puc.png'
import WI from '../../assets/workinvoice.png'
import POLI from '../../assets/poli.png'
import Item from './Item'
import WorkItem from './WorkItem'

function Experience() {
	const education = [
		{
			id: 1,
			title: 'Master of Science',
			place: 'Politecnico di Milano',
			location: 'Milan, Italy',
			years: '2021 - 2023',
			image: POLI,
			description: [
				"Master in Computer Science and Engineering.",
				"ICT Engineering, Business, and Innovation track.",
				"Awards: Double Degree Program student."
			]
		},
		{
			id: 2,
			title: 'Bachelor of Science',
			place: 'Pontificia Universidad Católica de Chile',
			location: 'Santiago, Chile',
			years: '2017 - 2021',
			image: PUC,
			description: [
				"Bachelor in Engineering.",
				"Major: Operational Research.",
				"Minor: Information Technology.",
				"Awards: Academic Excellence Award (Honor Tuition).",
				"Undergraduate research in linear optimization models and mobile app development.",
				"Grades: 5.9 (Equivalent to A+ in US, A in ECTS)."
			]
		}
	]

	const work = [
		{
			id: 1,
			title: 'Software Engineer',
			place: 'Euroclear | Full-time',
			location: 'Brussels, Belgium',
			years: 'December 2023 - Present',
			description: [
				"Designed and implemented new products within the Innovation Tribe.",
				"Developed RESTful APIs in C# and ASP.NET Core from scratch.",
				"Managed a complex data project integrating various cloud-centric solutions."
			]
		},
		{
			id: 2,
			title: 'Junior Software Engineer',
			place: 'Workinvoice | Internship',	
			location: 'Milan, Italy',
			years: 'January 2023 - July 2023',
			description: [
				"Developed a robust framework for selecting front-end technologies, considering quantitative and qualitative parameters, to provide a tool for development decision-making",
				"Led the analysis to select a new front-end technology evaluating Reagent, re-frame, htmx, and Svelte. Opted for htmx based on its outstanding performance metrics across diverse parameters.",
				"Developed the new front-end using htmx and Clojure as the main technology, replacing the old interfaces built using React and Mustache templates.",
				"Developed a RESTful API in Clojure following a hypermedia approach (HATEOAS).",
				"Achieved a remarkable 45\% average improvement in performance for the new implementation using Google Lighthouse benchmarks.",
				"Led a comprehensive analysis of programmer feedback, confirming heightened satisfaction after implementing the new technology."
			]
		}
	]

  	return (
    	<section id='experience'>
			<h5>Studies and work</h5>
			<h2>My experience</h2>
			<div className="container experience__container">
				<div className='experience__box'>
					<h3>Education</h3>
					<div className='experience__content'>
						{education.map((props) => {
							return (
								<Item {...props}/>
							)
						})}
					</div>
				</div>
				<div className='experience__box'>
					<h3>Work experience</h3>
					<div className='experience__content'>
						{work.map((props) => {
							return (
								<WorkItem {...props}/>
							)
						})}
					</div>
				</div>
			</div>
    	</section>
  )
}

export default Experience