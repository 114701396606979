import './Navbar.css'
import {useState, useEffect, useRef} from 'react'
import {AiOutlineHome, AiOutlineUser, AiOutlinePhone} from 'react-icons/ai'
import {MdWorkOutline} from 'react-icons/md'
import {FaRegLightbulb} from 'react-icons/fa'

function Navbar() {
	const [active, setActive] = useState('#')
	const navRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
		  const sections = document.querySelectorAll('section');
	
		  sections.forEach((section) => {
			const observer = new IntersectionObserver(
			  (entries) => {
				entries.forEach((entry) => {
				  if (entry.isIntersecting) {
					setActive(`#${entry.target.id}`);
				  }
				});
			  },
			  { threshold: 0.5 } // Adjust the threshold as needed
			);
	
			observer.observe(section);
		  });
		};
	
		window.addEventListener('scroll', handleScroll);
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		};
	  }, []);

  	return (
		<nav ref={navRef}>
				<a href="#home" className={active === '#home' ? 'active' : ''} onClick={() => setActive('#home')}><AiOutlineHome /></a>
				<a href="#about" className={active === '#about' ? 'active' : ''} onClick={() => setActive('#about')}><AiOutlineUser /></a>
				<a href="#experience" className={active === '#experience' ? 'active' : ''} onClick={() => setActive('#experience')}><MdWorkOutline /></a>
				<a href="#portfolio" className={active === '#portfolio' ? 'active' : ''} onClick={() => setActive('#portfolio')}><FaRegLightbulb /></a>
				<a href="#contact" className={active === '#contact' ? 'active' : ''} onClick={() => setActive('#contact')}><AiOutlinePhone /></a>
		</nav>
  )
}

export default Navbar