import React from 'react'
import './Footer.css'
import { AiFillLinkedin, AiFillGithub } from 'react-icons/ai';

function Footer() {
  return (
    <footer>
      <a href='' className='footer__logo'>Tomás Droppelmann</a>
      <ul className='permalinks'>
        <li><a href='#home'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#portfolio'>Projects</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <div className='footer__socials'>
        <a href="https://www.linkedin.com/in/tomas-droppelmann-pidal-41799a179/">  
          <AiFillLinkedin size='1.5rem'/>
        </a>
        <a href="https://github.com/tedroppelmann">  
          <AiFillGithub size='1.5rem'/>
        </a>
      </div>
    </footer>
  )
}

export default Footer