import React from 'react'
import './About.css'
import ME from '../../assets/me-square.png'


function About() {
  return (
    <section id='about'>
      <h5>A bit</h5>
      <h2>About me</h2>
      <div className="container about__container">
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt='About image' />
          </div>
        </div>
        <div className='about__content'>
        <p>
            My name is Tomás Droppelmann, I am 25 years old, and I was born and raised in Santiago, Chile. 
            After completing my undergraduate studies, I moved to Italy to pursue a master's degree in Computer Science. 
            Currently, I am working as a Software Engineer in Brussels.
        </p>
        <br/>
        <p>
            I love exploring new places, trying new things, and meeting new people. 
            I am always on the lookout for opportunities to learn and grow, and I am open to moving to other parts of Europe to continue 
            my education and gain new experiences. My passions include programming, reading, travelling and playing the guitar, 
            which provide me with both relaxation and a creative outlet for my ideas.
        </p>
        <br/>
        <p>
            I am enthusiastic about the future and the opportunities it holds. 
            I am dedicated to personal and professional growth, and I am always open to 
            new challenges and experiences that will help me achieve this.
        </p>
        </div>
      </div>
    </section>
  )
}

export default About